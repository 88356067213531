import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap, throwError, timeout } from 'rxjs';
import { URL } from '../../core/configs/url';
import { AdvertisementPOST, AdvertisementPUT } from '../../core/models/advertisement.interface';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementsService {
  private timeoutRequisition:number = 10000;
  private advertisementSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private httpClient:HttpClient) { }

  cachedAdvertisement():Observable<any>{
    return this.advertisementSubject.asObservable();
  }

  getAdvertisement(updateAds:boolean=true):Observable<any>{
    var params:string = '';
    params += '?page=1&pageSize=50';
    var completeUrl = `${URL.settings.ads}${params}`;
    //console.log("URL >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>" + completeUrl)
    return this.httpClient.get<any>(`${URL.settings.ads}${params}`, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => { this.handleResponseHttp('Erro ao consultar os anuncios',response,updateAds) }),
        map((response:any) => ( { message: 'Successo ao consultar os anuncios', data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'Error ao consultar os anuncios', errorDetails: error }));
        })
      );
  }

  createAdvertisement(body:AdvertisementPOST,updateAds:boolean=true):Observable<any>{
    var params:string = '';
    var completeUrl = `${URL.settings.ads}${params}`;
    //console.log("URL >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>" + completeUrl);

    return this.httpClient.post<any>(`${URL.settings.ads}`, body, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => { this.handleResponseHttp('Erro ao cadastrar anuncio',response,updateAds) }),
        map((response:any) => ( { message: 'successo ao cadastrar anuncio', data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao cadastrar anuncio', errorDetails: error }));
        })
      );
  }

  updateAdvertisement(code:string, bodyRequest:AdvertisementPUT,updateAds:boolean=true): Observable<any>{
    return this.httpClient.put<any>(`${URL.settings.ads}/${code}`, bodyRequest, {observe: 'response'})
      .pipe(
        //timeout(this.timeoutRequisition),
        tap((response:any) => { this.handleResponseHttp('Erro ao atualizar anuncio',response,updateAds) }),
        map((response:any) => ({ message: 'sucesso ao atualizar anuncio', data: response.body })),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao atualizar anuncio', errorDetails: error }));
        })
      );
  }

  deleteAdvertisement(code:string,updateAds:boolean=true): Observable<any>{
    return this.httpClient.delete<any>(`${URL.settings.ads}/${code}`, { observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => { this.handleResponseHttp('Erro ao atualizar anuncio',response,updateAds) }),
        map((response:any) => ({ message: 'sucesso ao deletar anuncio '+code, data: response.body })),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao deletar anuncio '+code, errorDetails: error }));
        })
      );
  }

  adsImage(blobFile:any): Observable<any>{
    if (!(blobFile instanceof Blob)) {
      console.error('O arquivo não é um Blob válido.');
      return of(null);
    }else{
      console.log('O arquivo é um Blob válido.');
    }

    const formData = new FormData();
    formData.append('file',blobFile);
    //formData.append('email','externo@cargolift.com.br');
    //formData.append('senha','sup#1234');
    //formData.append('client_id','wms_app');

    const httpOptions = {
      headers: new HttpHeaders({
        // Content-Type não deve ser definido aqui; o Angular configura automaticamente
      }),
      observe: "response" as const
    };

    // https://cargo-homologacao.wiser.log.br/api/v1/auth

    return this.httpClient.post<any>(`${URL.settings.upload}`, formData , httpOptions)
    //return this.httpClient.post<any>(`${'https://cargo-homologacao.wiser.log.br/api/v1/auth'}`, formData , httpOptions)
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => { this.handleResponseHttp('Erro ao fazer upload da imagem',response) }),
        map((response:any) => ( { message: 'successo ao fazer upload', data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao fazer upload', errorDetails: error }));
        })
      );
  }

  private handleResponseHttp(msgErro:string, response:any,updateAds:boolean = false){
    if (response.status !== 200) {
      throw { message: msgErro, errorDetails: response };
    }
    updateAds ? this.advertisementSubject.next(response.body?.items) : '';
  }

  clearSubject(): void {
    this.advertisementSubject.next(undefined);
  }
}
